import React, { Component } from "react";
import "./Filters.scss";
import "react-day-picker/lib/style.css";
import Dropdown from "react-bootstrap/Dropdown";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import _get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { bindActionCreators } from "redux";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import { getFormattedPriceValue, generateFormattedEstimateValue } from "../helper";
import { getCookie } from "../helper";
import AlertImpactFilter from "../containers/Adoption/AlertImpactFilter";
import UserNameFilter from "../containers/Adoption/UserNameFilter";
import CustomDateFilter from "./CustomDateFilter";
import AlertDateFilter from "./AlertDateFilter";
import RankTypeFilter from "./RankTypeFilter";
import InsightsDateFilter from './InsightsDateFilter';
import {
  DropDownIcon,
  TrashIcon,
  RightArrowIcon,
  BookmarkIcon,
  FilterIcon,
  CloseIcon,
} from "./Icons";
import ListGroup from "react-bootstrap/ListGroup";
import Swal from "sweetalert2";
import {
  saveFilters,
  deleteFilter,
  getSavedFilters,
  getLastDataRefreshDate,
  getGlobalFilter,
  storeRecommendationSelectedStrategies,
  storeGlobalFilterSelections,
  storeGlobalFilterValues,
  storeSaveFilterClicked,
  storeExpandButtonClickedInfo,
  storeNewRecomSearchText,
  exportAdoptionData,
  saveDateRangeFilter,
  recommendationTableContent,
  strategySelectedDateRange,
  strategyFilterAndOrCondition,
} from "../actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import DateFilter from "./DateFilter";
import { withOktaAuth } from "@okta/okta-react";
import RecommendationFilter from "./RecommendationFilter";
import UserCaseFilter from "../containers/Adoption/UseCaseFilter";
import { Buffer } from 'buffer';
import { saveAs } from "file-saver";
import * as XLSX from 'xlsx';

class Filters extends Component {
  constructor(props) {
    super(props);

    // many long objects need to be maintained, these have been pushed to a constants file and
    // are imported above e.g. multiSelectOptions, mappingCamelCase etc

    let finalStrategyFilter = this.props.isAdoption
      ? this.props.adoptionStrategiesSelected
      : this.props.selectedStrategies;

    this.state = {
      displayFilterDropdown: false,
      displaySaveDropdown: false,
      expanded: false,
      defaultChecked: false,
      error: "",
      displayDate: false,
      displayAccordion: true,
      selectedItems: {}, // only key with empty array
      mappingCamelCase: {}, // with camel case
      selectedItemsToDisplay: {}, // only key with empty array
      hoveredGlobalFilter: "",
      multiSelectOptions: this.props.selectedGlobalFilterValues ? this.props.selectedGlobalFilterValues : {}, // real data
      selectedDateRange: "Last 1 week",
      compareToOption: "Prior period",
      startDateCompare: "",
      endDateCompare: "",
      startDateCurrent: "",
      endDateCurrent: "",
      searchString: "",
      newRecomSearchText: "",
      filterDesc: "",
      filterError: "",
      numOfSelectedItems: 0,
      actioncomments: "",
      selectedStrategies: finalStrategyFilter,
      actionCheckBoxClicked: false,
      saveFilterClicked: false,
      actionButtonClicked: false,
      expandButtonClicked: false,
      fullScreenIcon: "expand",
      alertsSelected: [],
      isSecure:""
    };
  }

  componentDidMount() {
    if (window.location.hostname !== "localhost") {
			this.setState({ isSecure:'secure'});
		}
    if (
      this.props.selectedGlobalFilters ||
      this.props.subscriptionFilterValues
    ) {
      let filterObj = this.props.displayUnsubscribeButton
        ? this.props.subscriptionFilterValues
        : getCookie("selected-global-filters")       
        ? getCookie("selected-global-filters")
        : this.props.selectedGlobalFilters;
      let count = 0;
      Object.keys(filterObj).map((key) => {
        count += filterObj[key].length;
      });

      this.setState(
        {
          numOfSelectedItems: count,
          selectedItemsToDisplay: filterObj,
          selectedItems: filterObj,
          displaySelectedFilters: true,
          multiSelectOptions:
            this.props.globalFilter && this.props.globalFilter.filterValues,
          mappingCamelCase:
            this.props.globalFilter && this.props.globalFilter.columnMapping,
          hoveredGlobalFilter: this.props.preselectedGlobalFilters && this.props.preselectedGlobalFilters.length 
            ? this.props.preselectedGlobalFilters[0].mainFilter
            : (this.props.globalFilter &&
            this.props.globalFilter.columnMapping
            ? Object.keys(this.props.globalFilter.columnMapping)[0] : '')
        },
        () => {
          this.props.getFilteredResults &&
            this.props.getFilteredResults(this.state.selectedItemsToDisplay);
         if(!getCookie("selected-global-filters") ){
          this.props.storeGlobalFilterSelections(
            this.props.globalFilter.defaultUserFilters
          );
         }
         if(this.props.globalFilter && this.props.globalFilter.filterValues && getCookie("selected-filter-name")) {
           let allFilterValues = cloneDeep(this.props.globalFilter.filterValues)
           this.updateMainFilterValues(filterObj, allFilterValues)
         }else if(this.props.globalFilter && this.props.globalFilter.filterValues) {
           this.updatedMultiSelectOptions(this.props.globalFilter.filterValues, this.props.globalFilter.defaultOptions)
         }
        }
      );
      if(!this.props.displayUnsubscribeButton) {
        let allCookies = document.cookie.split(';').reduce((ac, str) => Object.assign(ac, {[str.split('=')[0].trim()]: str.split('=')[1]}), {})
        Object.keys(allCookies).forEach(cookie => {
          cookie = cookie.trim()
          if(cookie && (!cookie.includes("ga") && !cookie.includes("gid") && cookie !== "selected-filter-name" && cookie !== "selected-filter-object" 
            && cookie !== "selected-parent-filter") && cookie !== "selected-global-filters") {
              if(cookie.includes("selected-parent-filter-") || cookie.includes("selected-filter-name-") || cookie.includes("selected-filter-object-")
                || cookie.includes("selected-global-filters-")){
                document.cookie = `${cookie}= ; path=/; max-age=0;`
              }
          }
        })
      }
      let dateRangeFilter =  getCookie('selected-date-range-filters');      
      if(dateRangeFilter){
        this.props.saveDateRangeFilterClicked({
          'dateRange': dateRangeFilter.dateRange,
          'compareTo': dateRangeFilter.compareTo
        });
      }
    }
    this.setState({
      newRecomSearchText: this.props.newRecoSearchText,
      saveFilterClicked: this.props.saveFilterClicked,
    });

    this.props.oktaAuth.getUser().then((res, rej) => {
      if (res) {
      this.setState({ userInfo: res });
    } else {
    }
});
  }
  getSelectedGlobalFiltersCount = (filterObj) => {
    let count = 0;
    Object.keys(filterObj).map((key) => {
      count += filterObj[key].length;
    });
    return count;
  };
  componentWillReceiveProps(nextprops) {
    if(_isEqual(nextprops.newRecommendationSelectedRows, {}) && _isEqual(nextprops.newSelectAllProductList, [])){
      this.setState({actionButtonClicked:false})
    }
    if (
      nextprops.subscriptionFilterValues &&
      !_isEqual(
        this.props.subscriptionFilterValues,
        nextprops.subscriptionFilterValues
      )
    ) {
      let filterObj = nextprops.subscriptionFilterValues;
      let count = 0;
      Object.keys(filterObj).map((key) => {
        count += filterObj[key].length;
      });
      this.setState({
        numOfSelectedItems: count,
        selectedItemsToDisplay: filterObj,
        selectedItems: filterObj,
        displaySelectedFilters: true,
      });
    }

    if (
      nextprops.globalFilter.columnMapping &&
      !_isEqual(
        this.state.mappingCamelCase,
        nextprops.globalFilter.columnMapping
      )
    ) {
      this.setState({ mappingCamelCase: nextprops.globalFilter.columnMapping });
    }
    if (
      nextprops.globalFilter.filterValues &&
      !_isEqual(
        this.state.multiSelectOptions,
        nextprops.globalFilter.filterValues
      )
    ) {
      if(getCookie("selected-filter-name") && nextprops.globalFilter.filterValues) {
        let allFilterValues = nextprops.globalFilter.filterValues
        let filterObj = this.props.displayUnsubscribeButton
        ? this.props.subscriptionFilterValues
        : getCookie("selected-global-filters")       
        ? getCookie("selected-global-filters")
        : this.props.selectedGlobalFilters;
        this.updateMainFilterValues(filterObj, allFilterValues)
      }else {
        this.updatedMultiSelectOptions(nextprops.globalFilter.filterValues, nextprops.globalFilter.defaultOptions)
      }
    }
    if (
      nextprops.newRecoSearchText &&
      !_isEqual(this.state.newRecomSearchText, nextprops.newRecoSearchText)
    ) {
      this.setState({
        newRecomSearchText: nextprops.newRecoSearchText,
      });
    }
    if (
    !this.props.displayUnsubscribeButton &&  nextprops.selectedGlobalFilters &&
      !_isEqual(
        this.state.selectedItemsToDisplay,
        nextprops.selectedGlobalFilters
      )
    ) {

      this.setState({
        selectedItems: nextprops.selectedGlobalFilters,
        numOfSelectedItems: this.getSelectedGlobalFiltersCount(
          nextprops.selectedGlobalFilters
        ),
        selectedItemsToDisplay: nextprops.selectedGlobalFilters,
      });
    }

    if (nextprops.globalFilter.columnMapping && nextprops.preselectedGlobalFilters && nextprops.preselectedGlobalFilters.length) {
      let count = 0
      Object.keys(nextprops.selectedGlobalFilters).map((key)=> {
        count += nextprops.selectedGlobalFilters[key].length
      })
      if(count && !getCookie("selected-filter-name")) {
        this.resetCookies(nextprops.preselectedGlobalFilters)
      }
      this.setState({
        hoveredGlobalFilter: nextprops.preselectedGlobalFilters[0].mainFilter ? nextprops.preselectedGlobalFilters[0].mainFilter : Object.keys(
          nextprops.globalFilter.columnMapping
        )[0]
      });
    }
    !nextprops.isAdoption &&
      this.setState({ selectedStrategies: nextprops.selectedStrategies });
  }
  handleActionButtonClick = (e) => {
    let buttonStatus = this.state.actionButtonClicked;
   
    // hard code for snooze
    this.props.newRecommendationStrategySelection.selection == "active" && this.props.isSnoozeSubProject && this.props.displayActionModalOn()
    this.setState({
      actionButtonClicked: !buttonStatus,
    });
  };

  handleChange = (e, item, isClearFilter) => {
    // depending on whether item is selected or deselected, it needs to be removed or added from selectedItems array
    const selectedItems = cloneDeep(this.state.selectedItems);
    let numOfSelectedItems = this.state.numOfSelectedItems;
    let allFilterValues = this.state.multiSelectOptions ? cloneDeep(this.state.multiSelectOptions) : this.props.globalFilter.filterValues
    let arr;
    let selectedParentFilter = this.props.noDepValueFilters.includes(this.state.hoveredGlobalFilter) ? getCookie("selected-parent-filter") ? getCookie("selected-parent-filter") : [] : []
    let selectedFilterName = getCookie("selected-filter-name") ? getCookie("selected-filter-name") : []
    let selectedFilterObj = getCookie("selected-filter-object") ? getCookie("selected-filter-object") : []

    if (e == true || (e && e.target && e.target.checked)) {
      if(!selectedFilterName.includes(this.state.hoveredGlobalFilter))
        selectedFilterName.push(this.state.hoveredGlobalFilter)
      this.storeFilterNameIntoCookie(selectedFilterName)
      selectedFilterObj.push({val: item.value, filterName: this.state.hoveredGlobalFilter, parent: item.parents})
      this.storeFilterObjIntoCookie(selectedFilterObj)
      if (selectedItems && selectedItems[this.state.hoveredGlobalFilter] && selectedItems[this.state.hoveredGlobalFilter].indexOf(item.value) < 0) {
        arr = selectedItems[this.state.hoveredGlobalFilter];
        arr.push(item.value);
        let filteredObj = selectedFilterObj.filter(filterVal1 => filterVal1.filterName === this.state.hoveredGlobalFilter)
        filteredObj.forEach(values => {
          selectedParentFilter = selectedParentFilter.concat(values.parent)
        })
        if(!this.props.noDepValueFilters.includes(this.state.hoveredGlobalFilter)) {
          this.storeParentFilterIntoCookie(selectedParentFilter)
        }
        let noDepFiltersCount = 0
        selectedFilterName.forEach(filter => {
          noDepFiltersCount = this.props.noDepValueFilters.includes(filter) ? noDepFiltersCount + 1 : noDepFiltersCount
        })
        Object.keys(allFilterValues).forEach(filterName => {
          allFilterValues[filterName].forEach((filterValue, index)  => {
            if((item.value === filterValue.value) || selectedParentFilter.includes(filterValue.value) || (filterName === selectedFilterName[0] || 
              (filterName === selectedFilterName[noDepFiltersCount])) || filterValue.parents.length === 0 
              || (selectedFilterName && selectedFilterName.length === 1 && selectedFilterName.includes('special_filter'))
              || (selectedFilterName && selectedFilterName.length === 1 && selectedFilterName.includes('rpln_flag'))
              || (selectedFilterName && selectedFilterName.length === 2 && selectedFilterName.includes('special_filter') && selectedFilterName.includes('rpln_flag'))
              || this.props.noDepValueFilters.includes(filterName)) {
              allFilterValues[filterName][index].isDisabled =  false
            }else if(!selectedFilterName.includes(filterName)) {
              allFilterValues[filterName][index].isDisabled = true
            }
          })
        })
        numOfSelectedItems += 1;
      }
    } else {
      selectedFilterName.forEach(filterName1 => {
        let deselectedFilter = selectedItems[filterName1]?.includes(item.value) ? selectedItems[filterName1].filter(val => val !== item.value) : selectedItems[filterName1]
        if(deselectedFilter && deselectedFilter.length === 0)
          selectedFilterName = selectedFilterName.filter(filterVal1 => filterVal1 !== this.state.hoveredGlobalFilter)
      })
      this.storeFilterNameIntoCookie(selectedFilterName)
      arr = selectedItems[this.state.hoveredGlobalFilter];
      let index = arr.indexOf(item.value);
      arr.splice(index, 1);
      selectedFilterObj = selectedFilterObj.filter(filter => filter.val !== item.value)
      let filterName3 = selectedFilterName.length > 1 ? selectedFilterName[selectedFilterName.length-1] : selectedFilterName[0]
      let filterObj = selectedFilterObj.filter(filter => filter.filterName === filterName3)
      this.storeFilterObjIntoCookie(selectedFilterObj)
      selectedParentFilter = this.props.noDepValueFilters.includes(this.state.hoveredGlobalFilter) ? getCookie("selected-parent-filter") ? getCookie("selected-parent-filter") : [] : []
      filterObj.forEach(filters => {
        selectedParentFilter = selectedParentFilter.concat(filters.parent)
      })
      if(!this.props.noDepValueFilters.includes(this.state.hoveredGlobalFilter)) {
        this.storeParentFilterIntoCookie(selectedParentFilter)
      }
      if(selectedFilterName.length || selectedParentFilter.length) {
        let noDepFiltersCount = 0
        selectedFilterName.forEach(filter => {
          noDepFiltersCount = this.props.noDepValueFilters.includes(filter) ? noDepFiltersCount + 1 : noDepFiltersCount
        })
        
        Object.keys(allFilterValues).forEach(filterName => {
          allFilterValues[filterName].forEach((filterValue, index)  => {
            if(selectedParentFilter.includes(filterValue.value) || selectedParentFilter.includes(filterValue.value) || (filterName === selectedFilterName[0] || (filterName === selectedFilterName[noDepFiltersCount])) || filterValue.parents.length === 0 
            || (selectedFilterName && selectedFilterName.length === 1 && selectedFilterName.includes('special_filter'))
            || (selectedFilterName && selectedFilterName.length === 1 && selectedFilterName.includes('rpln_flag'))
            || (selectedFilterName && selectedFilterName.length === 2 && selectedFilterName.includes('special_filter') && selectedFilterName.includes('rpln_flag'))
            || this.props.noDepValueFilters.includes(filterName)) {
              allFilterValues[filterName][index].isDisabled =  false
            }else if(!selectedFilterName.includes(filterName)) {
              allFilterValues[filterName][index].isDisabled = true
            }
          })
        })
      }else {
        Object.keys(allFilterValues).forEach(filterName => {
          allFilterValues[filterName].forEach((filterValue, index)  => {
            allFilterValues[filterName][index].isDisabled =  false
          })
        })
        this.storeParentFilterIntoCookie("")
        this.storeFilterNameIntoCookie("")
        this.storeFilterObjIntoCookie("")
      }
      numOfSelectedItems = Math.max(0, numOfSelectedItems - 1);
    }
    this.setState({
      selectedItems: selectedItems,
      numOfSelectedItems: numOfSelectedItems,
      multiSelectOptions: allFilterValues
    });
  };

  handleApplyDateFilter = (selectedDateRange, compareToOption) => {
    // whichever component needs the dates after click of apply button
    // needs to have a setStartDateAndCompareToPeriod method and pass it as props to filter
    this.props.setStartDateAndCompareToPeriod(
      selectedDateRange,
      compareToOption
    );
    if(selectedDateRange && compareToOption) {
      this.props.saveDateRangeFilterClicked({
        'dateRange': selectedDateRange,
        'compareTo': compareToOption
      });
      this.storeDateRangeCookie({
        'dateRange': selectedDateRange,
        'compareTo': compareToOption
      });      
    }    
  };

  updateSingleDeselectFilter = (filterNameVal, itemValue) => {
    let selectedItems = cloneDeep(this.state.selectedItems)
    let allFilterValues = cloneDeep(this.state.multiSelectOptions)
    let selectedParentFilter = []
    let selectedFilterName = getCookie("selected-filter-name") ? getCookie("selected-filter-name") : []
    let selectedFilterObj = getCookie("selected-filter-object") ? getCookie("selected-filter-object") : []
    selectedFilterName.forEach(filterName1 => {
      let deselectedFilter = selectedItems[filterName1]?.includes(itemValue) ? selectedItems[filterName1].filter(val => val !== itemValue) : selectedItems[filterName1]
      if(deselectedFilter && deselectedFilter.length === 0)
        selectedFilterName = selectedFilterName.filter(filterVal1 => filterVal1 !== filterNameVal)
    })
    this.storeFilterNameIntoCookie(selectedFilterName)
    selectedFilterObj = selectedFilterObj.filter(filter => filter.val !== itemValue)
    let filterName3 = selectedFilterName.length > 1 ? selectedFilterName[selectedFilterName.length-1] : selectedFilterName[0]
    let filterObj = selectedFilterObj.filter(filter => filter.filterName === filterName3)
    this.storeFilterObjIntoCookie(selectedFilterObj)
    selectedParentFilter = selectedFilterName && (selectedFilterName.includes('special_filter') || selectedFilterName.includes('rpln_flag')) ? getCookie('selected-parent-filter') ? getCookie('selected-parent-filter') : [] : []
    filterObj.forEach(filters => {
      selectedParentFilter = selectedParentFilter.concat(filters.parent)
    })
    this.storeParentFilterIntoCookie(selectedParentFilter)
    if(selectedFilterName.length || selectedParentFilter.length) {
      let noDepFiltersCount = 0
      selectedFilterName.forEach(filter => {
        noDepFiltersCount = this.props.noDepValueFilters.includes(filter) ? noDepFiltersCount + 1 : noDepFiltersCount
      })
      
      Object.keys(allFilterValues).forEach(filterName => {
        allFilterValues[filterName].forEach((filterValue, index)  => {
          if(selectedParentFilter.includes(filterValue.value) || selectedParentFilter.includes(filterValue.value) || (filterName === selectedFilterName[0] || (filterName === selectedFilterName[noDepFiltersCount])) || filterValue.parents.length === 0 
            || (selectedFilterName && selectedFilterName.length === 1 && selectedFilterName.includes('special_filter'))
            || (selectedFilterName && selectedFilterName.length === 1 && selectedFilterName.includes('rpln_flag'))
            || (selectedFilterName && selectedFilterName.length === 2 && selectedFilterName.includes('special_filter') && selectedFilterName.includes('rpln_flag'))
            || this.props.noDepValueFilters.includes(filterName)) {
            allFilterValues[filterName][index].isDisabled =  false
          }else if(!selectedFilterName.includes(filterName)){
            allFilterValues[filterName][index].isDisabled = true
          }
        })
      })
    }else {
      Object.keys(allFilterValues).forEach(filterName => {
        allFilterValues[filterName].forEach((filterValue, index)  => {
          allFilterValues[filterName][index].isDisabled =  false
        })
      })
      this.storeParentFilterIntoCookie("")
      this.storeFilterNameIntoCookie("")
      this.storeFilterObjIntoCookie("")
    }
    this.setState({multiSelectOptions: allFilterValues})
    this.props.storeGlobalFilterValues(allFilterValues)
  }

  storeDataIntoCookie = (data)=>{
    document.cookie = `selected-global-filters=${JSON.stringify(data)};path=/;max-age=28800;`;
  }

  storeParentFilterIntoCookie = (data)=>{
    document.cookie = `selected-parent-filter=${JSON.stringify(data)};path=/;max-age=28800;`;
  }

  storeFilterNameIntoCookie = (data)=>{
    document.cookie = `selected-filter-name=${JSON.stringify(data)};path=/;max-age=28800;`;
  }

  storeFilterObjIntoCookie = (data)=>{
    document.cookie = `selected-filter-object=${JSON.stringify(data)};path=/;max-age=28800;`;
  }

  storeDateRangeCookie = (data)=>{
    document.cookie = `selected-date-range-filters=${JSON.stringify(data)};path=/;max-age=3600;`;
  }

  updatedMultiSelectOptions = (data, defaultFilterOptions, isClearFilter) => {
    let allFilterValues = cloneDeep(data)
    Object.keys(allFilterValues || {}).forEach(filterName => {
      allFilterValues[filterName].forEach((filterValue, index)  => {
        allFilterValues[filterName][index].isDisabled =  false
      })
    })
    this.setState({multiSelectOptions: allFilterValues})
  }

  resetCookies = (defaultOptions) => {
    defaultOptions.forEach(option => {
      this.storeFilterNameIntoCookie([option.mainFilter])
      this.storeFilterObjIntoCookie([{val: option.options[0].value, filterName: option.mainFilter, parents: option.options[0].parents}])
    })
  }

  updateSavedFilters = (filterObject) => {
    if(filterObject.selectedParentFilter.length && filterObject.selectedFilterName.length && filterObject.selectedFilterObj.length) {
      this.storeParentFilterIntoCookie(filterObject.selectedParentFilter)
      this.storeFilterNameIntoCookie(filterObject.selectedFilterName)
      this.storeFilterObjIntoCookie(filterObject.selectedFilterObj)
    }else {
      let allFilterValues = cloneDeep(this.state.multiSelectOptions)
      let selectedParentFilter = getCookie("selected-parent-filter") ? getCookie("selected-parent-filter") : []
      let selectedFilterName = getCookie("selected-filter-name") ? getCookie("selected-filter-name") : []
      let selectedFilterObj = getCookie("selected-filter-object") ? getCookie("selected-filter-object") : []
      Object.keys(filterObject.filterValues).forEach(filterName1 => {
        if(filterObject.filterValues[filterName1] && filterObject.filterValues[filterName1].length && allFilterValues[filterName1] && allFilterValues[filterName1].length) {
          selectedFilterName.push(filterName1)
          this.storeFilterNameIntoCookie(selectedFilterName)
          allFilterValues[filterName1].forEach((filterValue, index)  => {
            if(filterObject.filterValues[filterName1].includes(filterValue.value)) {
              selectedParentFilter = selectedParentFilter.concat(filterValue.parents)
              this.storeParentFilterIntoCookie(selectedParentFilter)
              selectedFilterObj.push({val: filterValue.value, filterName: filterName1, parent: filterValue.parents})
              this.storeFilterObjIntoCookie(selectedFilterObj)
            }
          })
        }
      })
    }
  }

  updateMainFilterValues = (filterObj, allFilterValues) => {
    let selectedParentFilter = [], selectedFilterName1 = []
    let selectedFilterName = getCookie("selected-filter-name")
    let selectedFilterObj = getCookie("selected-filter-object")
    selectedFilterName.forEach(hoveredGlobalFilter => {
      selectedFilterName1.push(hoveredGlobalFilter)
      if(filterObj[hoveredGlobalFilter] && filterObj[hoveredGlobalFilter].length && allFilterValues[hoveredGlobalFilter] && allFilterValues[hoveredGlobalFilter].length) {
        filterObj[hoveredGlobalFilter].forEach(item  => {
          selectedParentFilter = selectedFilterName && (selectedFilterName.includes('special_filter') || selectedFilterName.includes('rpln_flag')) ? getCookie("selected-parent-filter") : []
          let filteredObject = selectedFilterObj.filter(filterVal1 => filterVal1.filterName === hoveredGlobalFilter)
          filteredObject.forEach(values => {
            selectedParentFilter = selectedParentFilter.concat(values.parent)
          })
          if(this.props.noDepValueFilters && this.props.noDepValueFilters.length && !this.props.noDepValueFilters.includes(hoveredGlobalFilter)) {
            this.storeParentFilterIntoCookie(selectedParentFilter)
          }
          let noDepFiltersCount = 0
          selectedFilterName.forEach(filter => {
            noDepFiltersCount = this.props.noDepValueFilters.includes(filter) ? noDepFiltersCount + 1 : noDepFiltersCount
          })
          
          Object.keys(allFilterValues).forEach(filterName => {
            allFilterValues[filterName].forEach((filterValue, index)  => {
              if((item.value === filterValue.value) || selectedParentFilter.includes(filterValue.value) || (filterName === selectedFilterName[0] || (filterName === selectedFilterName[noDepFiltersCount])) || filterValue.parents.length === 0
                || (selectedFilterName && selectedFilterName.length === 1 && selectedFilterName.includes('special_filter'))
                || (selectedFilterName && selectedFilterName.length === 1 && selectedFilterName.includes('rpln_flag'))
                || (selectedFilterName && selectedFilterName.length === 2 && selectedFilterName.includes('special_filter') && selectedFilterName.includes('rpln_flag'))
                || this.props.noDepValueFilters.includes(filterName)) {
                allFilterValues[filterName][index].isDisabled =  false
              }else if(!selectedFilterName1.includes(filterName)) {
                allFilterValues[filterName][index].isDisabled = true
              }
            })
          })
        })
      }
    })
    this.setState({multiSelectOptions: allFilterValues})
    this.props.storeGlobalFilterValues(allFilterValues)
  }
  
  handleApplyFilter(e) {
    // whenever apply  button is clicked, we need to copy non-empty keys from selectedItems array
    // and set it to selectedItemsToDisplay
    if(e) {
      e.preventDefault();
    }
    document.cookie = "selected-sku-id=; path=/; max-age=0;"
    let len = 0;
    for (let key in this.state.selectedItems) {
      if (this.state.selectedItems[key].length > 0) {
        len++;
      }
    }
    if (len === 0) {
      this.setState({ error: "Please select  a value" });
    } else {
      const selectedItems = cloneDeep(this.state.selectedItems);
      const multiSelectOption = cloneDeep(this.state.multiSelectOptions);
      this.setState(
        {
          saveFilterClicked: false,
          displayFilterDropdown: false,
          displaySelectedFilters: true,
          error: "",
          selectedItemsToDisplay: selectedItems
        },
        () => {
          this.props.storeGlobalFilterValues(multiSelectOption)
          this.props.storeSaveFilterClicked(false);

          if (!this.props.displayUnsubscribeButton) {

            this.props.storeGlobalFilterSelections(
              this.state.selectedItemsToDisplay
            );
            this.storeDataIntoCookie(this.state.selectedItemsToDisplay)
          }
          this.props.getFilteredResults &&
            this.props.getFilteredResults(
              this.props.strategyIndex,
              this.state.selectedItemsToDisplay
            );
        }
      );
    }
  }

  renderTooltip(props, name) {
    // render tooltip

    return (
      <Tooltip id="button-tooltip" {...props}>
        {props}
      </Tooltip>
    );
  }
  renderInfoTooltip(props, name) {
    return (
      <Tooltip id="info-tooltip">Applicable only for global filters</Tooltip>
    );
  }
  getTotalCountForProductsSelected = () => {
    let tempSelectedItemsToDisplay = cloneDeep(
      this.state.selectedItemsToDisplay
    );
    let count = 0;
    for (let key in tempSelectedItemsToDisplay) {
      if (tempSelectedItemsToDisplay[key].length > 0) {
        count++;
      }
    }
    return count;
  };
  renderSelectedFilters() {
    const { mappingCamelCase } = this.state;
    if (!mappingCamelCase) return null;
    let tempSelectedItemsToDisplay = cloneDeep(
      this.state.selectedItemsToDisplay
    );
    let notEmptySelectedItemsToDisplay = {};
    for (let key in tempSelectedItemsToDisplay) {
      if (tempSelectedItemsToDisplay[key].length > 0) {
        notEmptySelectedItemsToDisplay[key] = tempSelectedItemsToDisplay[key];
      }
    }
    return Object.keys(notEmptySelectedItemsToDisplay).map((item, index) => {
      let str = notEmptySelectedItemsToDisplay[item];
      if (!mappingCamelCase.hasOwnProperty(item))
        return <div>Not applicable</div>;

      return notEmptySelectedItemsToDisplay[item].map((each) => {
        if(this.props.digitalShelfProp && (each === 'Replenishable' || each === 'Non Replenishable')) {
          return false;
        } else {
        return (
          <li
            className="filtered"
            key={index}
            onClick={(e) => {
              this.onToggle(true);
            }}
          >
            <b>{each}</b>
            <svg
              width="1em"
              height="1em"
              onClick={(e) => {
                let objToDisplay = cloneDeep(this.state.selectedItemsToDisplay);
                let count = this.state.numOfSelectedItems;
                const index = objToDisplay[item].indexOf(each);
                if (index > -1) {
                  objToDisplay[item].splice(index, 1);
                }
                this.updateSingleDeselectFilter(item, each)

                this.setState(
                  {
                    numOfSelectedItems:
                      notEmptySelectedItemsToDisplay[item] > 1
                        ? count
                        : count - 1,
                    selectedItems: objToDisplay,
                    selectedItemsToDisplay: objToDisplay,
                  },
                  () => {
                    if (!this.props.displayUnsubscribeButton) {

                      this.props.storeGlobalFilterSelections(
                        this.state.selectedItemsToDisplay
                      );
                      this.storeDataIntoCookie(this.state.selectedItemsToDisplay)
                    }
                    if (this.props.displayUnsubscribeButton)
                      this.props.getFilteredResults(
                        this.props.strategyIndex,
                        this.state.selectedItemsToDisplay
                      );
                    else {
                      this.props.getFilteredResults &&
                        this.props.getFilteredResults(
                          this.state.selectedItemsToDisplay
                        );
                    }
                  }
                );
              }}
              viewBox="0 0 16 16"
              className="close-icon ml-2 bi bi-x"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
              />
              <path
                fill-rule="evenodd"
                d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
              />
            </svg>
          </li>
        );
      }
      });
    });
  }

  saveFilter() {
    this.props
      .saveFiltersAPI({
        filterValues: this.state.selectedItemsToDisplay,
        filterName: this.state.filterDesc,
        filterDesc: this.state.filterDesc,
        user: this.state.userInfo.email,
        selectedParentFilter: getCookie("selected-parent-filter") ? getCookie("selected-parent-filter") : [],
        selectedFilterName: getCookie("selected-filter-name") ? getCookie("selected-filter-name") : [],
        selectedFilterObj: getCookie("selected-filter-object") ? getCookie("selected-filter-object") : [],
      })
      .then((res) => {
        this.onToggleSaveDropdown(false);
        if (res.data.responseType === "success") {
          toast.success(res.data.responseDesc, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          this.props.getSavedFiltersAPI();
        } else {
          toast.error(res.data.responseDesc, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      })
      .catch((err) => {
        toast.error(err, { position: toast.POSITION.TOP_CENTER });
      });
  }

  returnMomentObject(date) {
    return moment(date).utc().format("YYYY-MM-DD HH:mm:ss");
  }

  onToggle = (e) => {
    this.setState({ displayFilterDropdown: e });
  };

  onToggleSaveDropdown = (e) => {
    this.setState({ displaySaveDropdown: e });
  };
  renderGlobalFilterKeys() {
    const { mappingCamelCase } = this.state;
    if (!mappingCamelCase) {
      return null;
    }
    return (
      <React.Fragment>
        {Object.keys(mappingCamelCase).map((item, index) => {
          if(this.props.digitalShelfProp && mappingCamelCase[item] === 'Replenishable') {
            return null;
          } else {
          return (
            <ListGroup.Item
              key={index}
              className={`d-flex justify-content-between ${
                item === this.state.hoveredGlobalFilter ? "active" : null
              } `}
              onMouseEnter={(e) => {
                this.setState({ hoveredGlobalFilter: item });
              }}
            >                         
              <span className={this.props.project && this.props.project.toLowerCase() === 'ecompass' && getCookie("selected-filter-name") && getCookie("selected-filter-name").includes(item) ? "font-weight-bold" : null}>
              {mappingCamelCase[item]} {this.props.project && this.props.project.toLowerCase() === 'ecompass' && getCookie("selected-filter-name") && getCookie("selected-filter-name")[0] === item && <i class="ml-1 fas fa-dot-circle" style={{fontSize: "10px",bottom:"1px",position:"relative"}}></i>}</span>
              <RightArrowIcon />              
             
            </ListGroup.Item>
          );
          }
          return null;
        })}
      </React.Fragment>
    );
  }
  handleDeleteSavedFilter(filterName) {
    Swal.fire({
      text: `Are you sure you want to delete the saved filter ${filterName} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        this.props.deleteFilterAPI(filterName, (res, rej) => {
          if (res) {
            toast.success(res.data.responseDesc, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            this.props.storeSaveFilterClicked(false);
            this.props.getSavedFiltersAPI();
          } else {
            toast.error(res.data.responseDesc, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        });
      }
    });
  }
  renderSavedFilterValues() {
    const { hoveredGlobalFilter, multiSelectOptions } = this.state;
    const { savedFilters } = this.props;
    return this.props.savedFilters.map((item, index) => {
      if (
        item.filterName
          .toLowerCase()
          .indexOf(this.state.searchString.toLowerCase()) > -1
      ) {
        return (
          <ListGroup.Item className="d-flex justify-content-between">
            <div
              onClick={(e) => {
                let mainObject = JSON.parse(item.filterValues);
                let obj = mainObject.filterValues
                this.updateSavedFilters(mainObject)

                this.setState(
                  {
                    saveFilterClicked: true,
                    selectedItems: obj,
                    selectedItemsToDisplay: obj,
                    displaySelectedFilters: true,
                  },
                  () => {
                    this.props.storeSaveFilterClicked(true);

                    this.props.storeGlobalFilterSelections(
                      this.state.selectedItemsToDisplay
                    );
                    this.props.storeGlobalFilterValues(this.state.multiSelectOptions)
                    this.storeDataIntoCookie(this.state.selectedItemsToDisplay)
                    this.props.getFilteredResults &&
                        this.props.getFilteredResults(
                          this.props.strategyIndex,
                          this.state.selectedItemsToDisplay
                          );
                  }
                );
              }}
            >
              {item.filterName}
            </div>
            <div
              onClick={(e) => {
                this.handleDeleteSavedFilter(item.filterName);
              }}
            >
              <TrashIcon />
            </div>
          </ListGroup.Item>
        );
      }
      return null;
    });
  }
  renderGlobalFilterValues() {
    const {
      hoveredGlobalFilter,
      multiSelectOptions,
      selectedItems,
    } = this.state;
    const filterOptions = !_isEmpty(multiSelectOptions) ? cloneDeep(multiSelectOptions[hoveredGlobalFilter]):{};
    if (!multiSelectOptions || !selectedItems || !hoveredGlobalFilter) {
      return null;
    }
    if (
      Object.keys(multiSelectOptions).length === 0 ||
      Object.keys(selectedItems).length === 0
    ) {
      return null;
    }
    if (hoveredGlobalFilter === "Saved Filters") {
      return <ListGroup>{this.renderSavedFilterValues()}</ListGroup>;
    } else {
      let options = filterOptions?.filter((option) => {
        return (
          option.value.toLowerCase().indexOf(this.state.searchString.toLowerCase()) >
          -1
        );
      });

      if (hoveredGlobalFilter.toLowerCase() === "priority_sku") {
        return (
          <React.Fragment>
            {options.map((option, index) => {
              let check = false;
              if (
                this.state.selectedItems[hoveredGlobalFilter].indexOf(option.value) >
                -1 && !option.isDisabled
              ) {
                check = true;
              }
              return (
                <Form.Check
                  checked={check}
                  type="radio"
                  key={index}
                  required
                  name="priority-sku-filter"
                  label={option.value}
                  disabled={option.isDisabled}
                  onChange={(e) => {
                    this.handleChange(e, option, '');
                  }}
                  id={index}
                />
              );
            })}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          {options?.map((option, index) => {
            let check = false;
            let optionValue = option?.value ? option.value.substring(0,65) : '';
            if (
              selectedItems[hoveredGlobalFilter] &&
              selectedItems[hoveredGlobalFilter].indexOf(option.value) > -1 && !option.isDisabled
            ) {
                check = true;             
              
            }
            if(this.props.digitalShelfProp && hoveredGlobalFilter.toLowerCase() === 'rpln_flag') {
              return null;
            } else {            
              return (
                <Form.Check
                  key={index}
                  checked={check}
                  required
                  name="multiSelectFilter"
                  label={option.value}
                //   label={option?.value.length > 65 ? (<OverlayTrigger placement="top" overlay={<Tooltip id="globalfilter-tooltip">{option.value}</Tooltip>}>
                //   <i style={{fontStyle:'normal'}}>{optionValue}...</i>
                // </OverlayTrigger>) : option.value}
                  disabled={option.isDisabled}
                  onChange={(e) => {
                    this.handleChange(e, option, '');
                  }}
                  id={index}
                />
              );
            }
          })}
        </React.Fragment>
      );
    }
  }
  renderGlobalFilters() {
    let { selectedChannel } = this.state;
    if (Object.keys(this.props.globalFilter).length === 0) {
      return (
        <div className="d-flex globalFilters bg-white p-4">
          No data available
        </div>
      );
    }
    const updateFilter = (e) => {
      e.preventDefault();
      const initSelcItems ={};
      Object.keys(this.state.selectedItems)
        .forEach((key)=>{
          initSelcItems[key] = [];
        });
      this.setState({
          selectedItems: {...this.state.selectedItems,...initSelcItems},
          error: ""
      },()=>{
          this.setState({
            numOfSelectedItems: 0,
            selectedItemsToDisplay: {...this.state.selectedItemsToDisplay, ...initSelcItems}
          });
          this.renderGlobalFilterValues();
          this.storeDataIntoCookie(this.state.selectedItems);
      });      
    }
    return (
      <div className="d-flex globalFilters">
        <div id="globalFilterKeys">
          <ListGroup>
            {this.renderGlobalFilterKeys()}
            <div className="horizontal_divider"></div>
            <ListGroup.Item
              className={`d-flex justify-content-between ${
                "Saved Filters" === this.state.hoveredGlobalFilter
                  ? "active"
                  : null
              } `}
              onMouseEnter={(e) => {
                this.setState({ hoveredGlobalFilter: "Saved Filters" });
              }}
            >
              <span>
                <BookmarkIcon /> Saved Filters
              </span>
              <RightArrowIcon />
            </ListGroup.Item>
          </ListGroup>
        </div>
        <div id="globalFilterValues">
          <div class="d-flex align-items-center">
            <input
              type="text"
              value={this.state.searchString}
              onChange={(e) => {
                this.setState({ searchString: e.target.value });
              }}
              className="form-control mb-3"
              placeholder="Search"
              style={{ width: 190 }}
              // placeholder="Search for product"
            />
            {/* <i class="fa fa-search" style={{position:"relative",right:"34px",top:"-6px",cursor:"pointer", color:"#666"}}></i> */}
            {this.props.hideFilteredRow ? (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="tooltip-disabled">
                    These filters impact the Active and Actioned Alerts sections
                    and the Alert table
                  </Tooltip>
                }
              >
                <i
                  class="fas fa-info-circle"
                  style={{
                    fontSize: 18,
                    color: "#1a73e8",
                    marginLeft: 5,
                    marginBottom: 10,
                  }}
                ></i>
              </OverlayTrigger>
            ) : null}
          </div>

          <div className="globalFilterKeyWrapper">
            <div
              className={` ${
                this.state.hoveredGlobalFilter === "Saved Filters"
                  ? "saved_filters_values_list"
                  : "global_filter_values_list"
              }`}
            >
              {this.renderGlobalFilterValues()}
            </div>
            {this.state.hoveredGlobalFilter !== "Saved Filters" ? (
              <div className="apply_button_wrapper">
                <div className="horizontal_divider"></div>
                <div className="error">{this.state.error}</div>
                <div className="d-flex justify-content-between">
                  <button
                    className={`btn btn-disabled ${
                      this.state.numOfSelectedItems > 0
                        ? null
                        : "btn-disabled-clear-all"
                    } my-2`}
                    onClick={(e) => {
                      e.preventDefault();
                      const obj = this.props.globalFilter.selectedItems
                      this.setState({
                        selectedItemsToDisplay: obj,
                        selectedItems: obj,
                        error: '',
                        saveFilterClicked: false,
                        newRecomSearchText: ''
                      })
                      this.props.storeSaveFilterClicked(false);
                      this.props.storeGlobalFilterSelections(obj);
                      this.storeDataIntoCookie(obj)
                      this.storeParentFilterIntoCookie("")
                      this.storeFilterNameIntoCookie("")
                      this.storeFilterObjIntoCookie("")
                      this.updatedMultiSelectOptions(this.state.multiSelectOptions,  [], 'clear')
                      this.props.storeGlobalFilterValues({})
                      this.props.getFilteredResults &&
                        this.props.getFilteredResults(
                          this.props.strategyIndex,
                          this.props.globalFilter.selectedItems
                        );
                      document.cookie = "selected-sku-id=; path=/; max-age=0; this.state.isSecure;"
                    }}
                  >
                    Clear All
                  </button>
                  <button
                    className={`btn btn-apply  ${
                      this.state.numOfSelectedItems > 0
                        ? null
                        : "btn-disabled-apply"
                    } my-2`}
                    onClick={(e) => {
                      this.handleApplyFilter(e);
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
  renderIndividualSelectedItems(items) {
    let itemLists = items.map((item, index) => {
      if (item === "Select All") return "";
      else
        return (
          <li
            className={`filtered ${
              this.props.isAdoption ? "adoption-row" : "stratgies"
            }`}
            key={index}
          >
            <b>{item === 'Competitor OOS' ? 'Competition Out Of Stock' : item}</b>
            <svg
              width="1em"
              height="1em"
              onClick={(e) => {
                let arrIndex = index;
                let selectedStrategyItems = cloneDeep(items);
                selectedStrategyItems.splice(arrIndex, 1);
                let objKey = this.props.displayStrategyFilter
                  ? "selectedStrategies"
                  : "selectedUsers";
                if (selectedStrategyItems[0] === "Select All")
                  selectedStrategyItems.shift();
                this.setState({ objKey: selectedStrategyItems }, () => {
                  this.props.displayStrategyFilter
                    && this.handleStrategyClickInFilter(selectedStrategyItems)
                    
                });
                if(selectedStrategyItems.length <= 0){ 
                  this.props.strategySelectedDateRange('Default'); 
                  this.props.strategyFilterAndOrCondition(this.props.defaultAlertFilterCondition);          
                }
              }}
              viewBox="0 0 16 16"
              className="close-icon ml-2 bi bi-x"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
              />
              <path
                fill-rule="evenodd"
                d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
              />
            </svg>
          </li>
        );
    });
    return itemLists;
  }

  renderSelectedStrategiesRow() {
    if (
      this.state.selectedStrategies.length > 0 &&
      this.props.displayStrategyFilter
    ) {
      return (
        <div
          className={`col${
            this.props.isAdoption ? "" : "-12"
          } selectedFilters d-flex justify-content-between my-2`}
          id={this.props.isAdoption ? "adoption-filter" : ""}
        >
          <div className="d-flex alogn-items-center">
            <div className="text">Strategies :</div>
            <div className="d-flex">
              <ul>
                {this.renderIndividualSelectedItems(
                  this.state.selectedStrategies
                )}
              </ul>
            </div>
          </div>
          <div className="user-options">
            <span
              onClick={(e) => {
                this.handleStrategyClickInFilter([]);
                document.cookie = "selected-sku-id=; path=/; max-age=0; this.state.isSecure;"
              }}
            >
              Clear
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderUserOptions() {
    if (
      this.props.displayUnsubscribeButton &&
      this.props.displayUnsubscribeButton === true
    ) {
      return null;
    }
    return (
      <div
        className={`user-options ${
          this.props.isNewRecommendationPage ? "new-recommendation" : ""
        } `}
      >
        {this.state.saveFilterClicked ? (
          <button
            title="Saved Filter applied"
            className="btn btn-disabled-apply"
          >
            Save 
          </button>
        ) : (
          <Dropdown
            className="save"
            onToggle={this.onToggleSaveDropdown}
            show={this.state.displaySaveDropdown}
          >
            <Dropdown.Toggle
              variant="light"
              id="dropdown-basic"
              className=" btn-cta"
            >
              Save Filters
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="save-header">
                Save Filter
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderInfoTooltip()}
                >
                  <i
                    class="fas fa-info-circle"
                    style={{ paddingLeft: "10px", color: "#1a73e8" }}
                  ></i>
                </OverlayTrigger>
              </div>
              <div className="save-subheader">New Filter Name</div>
              <input
                type="text"
                className="mt-2 form-control"
                value={this.state.filterDesc}
                onChange={(e) => {
                  this.setState({ filterDesc: e.target.value });
                }}
              />
              <div>
                <div className="horizontal_divider"></div>
                <div className="text-right">
                  <button
                    className={`m-0 btn btn-apply  ${
                      this.state.numOfSelectedItems > 0
                        ? null
                        : "btn-disabled-apply"
                    } `}
                    onClick={(e) => {
                      this.saveFilter();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <span
          onClick={(e) => {
            e.preventDefault();
            const obj = this.props.globalFilter.selectedItems;
            this.setState({
              selectedItemsToDisplay: obj,
              selectedItems: obj,
              error: "",
              saveFilterClicked: false,
              newRecomSearchText: ""
            });
            this.props.storeSaveFilterClicked(false);
            this.props.storeGlobalFilterSelections(obj);
            this.storeDataIntoCookie(obj)
            this.storeParentFilterIntoCookie("")
            this.storeFilterNameIntoCookie("")
            this.storeFilterObjIntoCookie("")
            this.updatedMultiSelectOptions(this.state.multiSelectOptions, [], 'clear')
            this.props.storeGlobalFilterValues({})
            this.handleStrategyClickInFilter([]);
            this.props.storeExpandButtonClickedInfo(false);
            this.props.getFilteredResults && this.props.getFilteredResults();
            this.props.recommendationTableContent({loadStatus:'default',dynamicColumns:[], setOfDynamicColumns:[],sortedColumn:[],sortDirecetion:'',curentIndex:'',responseData:{productList:[]}, responseDesc:'', 
            responseType:'',selectedRows:{}, selectAllProductList :[]})
            document.cookie = "selected-sku-id=; path=/; max-age=0; this.state.isSecure;"
            this.props.strategySelectedDateRange('Default');
            this.props.strategyFilterAndOrCondition(this.props.defaultAlertFilterCondition);
          }}
        >
          Clear
        </span>
      </div>
    );
  }
  renderSelectedFilterRow() {
    let len = 0, selectedItemReplenis = false;
    for (let key in this.state.selectedItemsToDisplay) {
      if (this.state.selectedItemsToDisplay[key].length > 0) {
         if (key === 'rpln_flag') {
          selectedItemReplenis = true;
         }
        len++;
      }
    }

    if(len === 1 && this.props.digitalShelfProp && selectedItemReplenis) {
      return null;
    }
    else if (
      len > 0 ||
      (this.props.displayStrategyFilter &&
        this.state.selectedStrategies.length > 0)
    ) {
      return (
        <div
          className={`col-12 selectedFilters d-flex justify-content-between ${
            this.props.isNewRecommendationPage
              ? "new-recommendation-filter-row"
              : "my-2 recommendation-filter-row"
          }`}
        >
          <div className="d-flex align-items-center">
            <div className="text">Filters :</div>
            <div className="d-flex">
              <ul>
                {this.renderSelectedFilters()}
                {this.props.displayStrategyFilter &&
                  this.renderIndividualSelectedItems(
                    this.state.selectedStrategies
                  )}
                  {this.props.displayStrategyFilter && this.state.selectedStrategies.length > 1 && (
                    <li className="" style={{listStyle: 'none',padding:'3px 30px 0 140px'}}>
                      Alert Filter Condition :  <span>&nbsp;</span>
                    <strong>
                      {this.props.selectedFilterCondition ? this.props.selectedFilterCondition : this.props.defaultAlertFilterCondition}
                    </strong>
                    </li>
                  )}
              </ul>
            </div>
          </div>
          {this.renderUserOptions()}
        </div>
      );
    } else {
      return null;
    }
  }

  getSelectedAlerts = (data) => {
    this.setState({
      alertsSelected: [...data]
    })
  }

  handleStrategyClickInFilter = (listOfSelectedStrategies) => {
    this.setState({ selectedStrategies: listOfSelectedStrategies });
    if (!this.props.isAdoption) {
      let newRecommendationSelectedStrategyName = this.props
        .newRecommendationStrategySelection
        ? this.props.newRecommendationStrategySelection.strategyName
        : "";
      if (
        !listOfSelectedStrategies.includes(
          newRecommendationSelectedStrategyName
        )
      ) {
        let obj = {};
        if (listOfSelectedStrategies.length > 0) {
          obj["selection"] =
            this.props.newRecommendationStrategySelection &&
            this.props.newRecommendationStrategySelection.selection ? this.props.newRecommendationStrategySelection.selection : 'active';
        } else this.props.storeExpandButtonClickedInfo(false);

        this.props.storeRecommendationSelectedStrategies(obj);
      }
    }
    this.props.handleStrategyClick &&
      this.props.handleStrategyClick(listOfSelectedStrategies);
  };

  handleActionSaveButtonClicked = () => {
    this.props.handleActionButtonClick({
      comments: this.state.actioncomments,
      checkBoxSelection: this.state.actionCheckBoxClicked,
    });
    this.setState({
      actioncomments: "",
      actionCheckBoxClicked: false,
      actionButtonClicked: false,
    });
  };



  generateObject = (items, stringToBeAppeneded) => {
    let newObj = {};
    if (items)
      for (let item of items) newObj[item.x + stringToBeAppeneded] = item.y;
    return newObj;
  };
  generateFiltersObject = () => {
    let {
      adoptionSelectedUsers,
      adoptionCustomDates,
      adoptionSelectedStrategies,
    } = this.props.adoptionValues;
    let selectedDate = adoptionCustomDates ? adoptionCustomDates : {};
    return {
      selectedDate,
      users: adoptionSelectedUsers ? adoptionSelectedUsers : [],
      strategies: adoptionSelectedStrategies,
    };
  };
  getAdoptionChartData = () => {
    let {
      dailyUsageValue,
      averageVolumeDailyActions,
      estimatedValue,
      averageAgeofRecommendationsBeforeActioned,
      averageAgeofRecommendationsBeforeResolution,
      recommendationResolvedWithoutActioned,
    } = this.props.adoptionValues;
    let dailyUsagePercent =
      dailyUsageValue &&
      dailyUsageValue.responseData &&
      dailyUsageValue.responseData[0] &&
      dailyUsageValue.responseData[0].dailyPercentUsage;
    let avg =
      averageVolumeDailyActions &&
      averageVolumeDailyActions.responseData &&
      averageVolumeDailyActions.responseData[0] &&
      averageVolumeDailyActions.responseData[0].avgVolumeDailyActions;
    let estimateValue =
      estimatedValue &&
      estimatedValue.responseData &&
      estimatedValue.responseData.totalEstimatedVal;
      // commented out for future use
    let beforeActioned =
      averageAgeofRecommendationsBeforeActioned &&
      this.generateObject(
        averageAgeofRecommendationsBeforeActioned.responseData,
        " Before Actioned"
      );
    let beforeResolution =
      averageAgeofRecommendationsBeforeResolution &&
      this.generateObject(
        averageAgeofRecommendationsBeforeResolution.responseData,
        " Before Resolution"
      );
    let withoutActioned =
      recommendationResolvedWithoutActioned &&
      recommendationResolvedWithoutActioned.responseData &&
      recommendationResolvedWithoutActioned.responseData[0] &&
      recommendationResolvedWithoutActioned.responseData[0]
        .percentResolvedWithoutActioned;

    return {
      estVal: estimateValue ? estimateValue : "NA",
      dailyUsagePerc: dailyUsagePercent ? dailyUsagePercent :"NA",
      avgVolume: avg ? avg : "NA"
    };
  };
  downloadRecommendationCSVFile = () => {
 
    let { allStrategyDetails } = this.props.adoptionValues;
    let finalSortColumnValue = [];
    let filtrdArr = [];
    
    let alertsosFilter =  this.props.selectedStrategies?.length && this.props.selectedStrategies[0];
      finalSortColumnValue = this.props.sortedColumn && this.props.sortedColumn.length === 2 ? alertsosFilter === 'Share of Search' ? this.props.sortedColumn[1] : [this.props.sortedColumn[1]]: this.props.sortedColumn;
        let combinedObj = {
        filterValues: this.props.selectedGlobalFilters,
        recommendationFilters: this.props.selectedStrategies,
        currentIndex: this.props.totalCount,
        selectedTab: this.props.newRecommendationStrategySelection ? this.props.newRecommendationStrategySelection.selection : '',
        searchText: this.props.newRecoSearchText,
        sortDirecetion: this.props.sortDirecetion,
        sortedColumn: finalSortColumnValue,
        filterName: "",
        projectName: this.props.project,
        subprojectName: this.props.subproject,
        strategies: allStrategyDetails,
        alertInFocus: this.props.newRecommendationStrategySelection?.strategyName,
        selectedDateRange:this.props.selectedStrategyDateRange !== 'Default' ? this.props.strategyCustomDates : '',
        alertFilterCondition: this.props.selectedFilterCondition ? this.props.selectedFilterCondition : this.props.defaultAlertFilterCondition,
        columnMapping : this.props.globalFilter ? this.props.globalFilter.columnMapping : '',        
      };

      let combinedObjSos = {
        filterValues: this.props.selectedGlobalFilters,
        recommendationFilters: this.props.selectedStrategies,
        currentIndex: this.props.totalCount,
        selectedTab: this.props.newRecommendationStrategySelection ? this.props.newRecommendationStrategySelection.selection : '',
        searchText: this.props.newRecoSearchText,
        sortDirecetion: this.props.sortDirecetion,
        sortedColumn: finalSortColumnValue,
        filterName: "",
        projectname: this.props.project,
        subprojectname: this.props.subproject,
        strategies: allStrategyDetails,
        alertInFocus: this.props.newRecommendationStrategySelection?.strategyName,
        selectedDateRange:this.props.selectedStrategyDateRange !== 'Default' ? this.props.strategyCustomDates : '',
        rankTypeFilter: this.props.rankTypefilter?.length ? this.props.rankTypefilter : [],
        alertFilterCondition: this.props.selectedFilterCondition ? this.props.selectedFilterCondition : this.props.defaultAlertFilterCondition 
      };
    if(this.props.isSnoozeSubProject) {
      if(this.props.selectedStrategies && this.props.selectedStrategies?.length == 1) {
        filtrdArr = this.props.actionedAndActiveData?.responseData.filter((actAndActv) => {
          return this.props.selectedStrategies?.some((strName) => {
            if(actAndActv?.RECOMMENDATION_NAME === strName) {
              combinedObj.type = actAndActv.DISPLAY_TYPE;
              combinedObj.locationName = actAndActv?.LOCATION_NAME;
            }
            return actAndActv?.RECOMMENDATION_NAME === strName && (actAndActv.DISPLAY_TYPE === 'DC/Cluster' || actAndActv.DISPLAY_TYPE === 'Total' );
          });
        });        
      } else {
        filtrdArr = this.props.actionedAndActiveData?.responseData.filter((actAndActv) => {
          return this.props.selectedStrategies?.some((strName) => {
            combinedObj.type = actAndActv.DEFAULT_TYPE;
            combinedObj.locationName = actAndActv?.LOCATION_NAME;
            return actAndActv?.RECOMMENDATION_NAME === strName && ( actAndActv.DEFAULT_TYPE === 'DC/Cluster' || actAndActv.DEFAULT_TYPE === 'Total');
          });
        });
      }
    }
    
    let combined = {}
    if(alertsosFilter === 'Share of Search'){
      combined = combinedObjSos; 
    }else {
      combined = combinedObj;
    }
    let bufferValue = Buffer.from(JSON.stringify(combined));
    let base64data = bufferValue.toString("base64");
    let finalApiToBeCalled= this.props.isSnoozeSubProject ? alertsosFilter === 'Share of Search' ? "exportAlertRecommendationsForSos" : filtrdArr?.length > 0 ? "exportAlertRecommendationsByDC" : "exportAlertRecommendations" : "recommendations2"
    window.open(
     `${process.env.REACT_APP_ROOT_URL}/download/${finalApiToBeCalled}/${base64data}`,
      "_blank"
    );
  };
  downloadAlertLogHistoryCSVFile = () => {
 
     let combinedObj = {
      projectName: this.props.project,
      subprojectName: this.props.subproject,
      alertName: this.props.selectedStrategies,
      alertStatus: this.props.newRecommendationStrategySelection.selection,
      allSkuIds: this.props.newRecoSearchText ? this.props.allSkuIds : '',
      selectedDateRange:this.props.selectedStrategyDateRange !== 'Default' ? this.props.strategyCustomDates : '',
      alertFilterCondition: this.props.selectedFilterCondition ? this.props.selectedFilterCondition : this.props.defaultAlertFilterCondition,
    };
    let alertType = "";
    if(this.props.selectedStrategies?.length == 1) {
      let filterArr = this.props.actionedAndActiveData?.responseData.filter((actAndActv) => this.props.selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
      alertType = filterArr[0] ? filterArr[0]["DISPLAY_TYPE"] : "";
    } else {
      let filterArr = this.props.actionedAndActiveData?.responseData.filter((actAndActv) => this.props.selectedStrategies.find(( strName ) => actAndActv.RECOMMENDATION_NAME === strName));
      alertType = filterArr[0] ? filterArr[0]["DEFAULT_TYPE"] : "";
    }
    let bufferValue = Buffer.from(JSON.stringify(combinedObj));
    let base64data = bufferValue.toString("base64");
    let finalApiToBeCalled = alertType === "DC/Cluster" ||  alertType === "Total" ? "exportAlertLogDataNew" : "exportAlertLogData"
    window.open(
      `${process.env.REACT_APP_ROOT_URL}/download/${finalApiToBeCalled}/${base64data}`,
      "_blank"
    );
  };

  downloadSummary = (type) => {
      let { alertSummary, userSummary } = this.props.adoptionValues
      let currency = this.props.selectedRetailerCurrency
      let headers = []
      let bodyData = []
      if((type === 'Alert' && alertSummary && alertSummary.responseData && alertSummary.responseData.length) || 
        (type === 'User' && userSummary && userSummary.responseData && userSummary.responseData.length)) {
        if(type === 'Alert') {
          headers = ['Alert Name', 'Use Case','New Percent Total', 'New Estimated Value','Actions Taken Estimated Value', 'Avg Action time', 'Unresolved Actions Estimated Value', 'Resolved Estimated Value','Avg Resol. time',
          'Sales Impact Value','Sales Impact PoP', 'Effectiveness vs.Triggered','Effectiveness Triggered PoP','Effectiveness Triggered YoY','Effectiveness vs.Actioned','Effectiveness Actioned PoP','Effectiveness Actioned YoY'
        ]
          bodyData = alertSummary.responseData.map(item => {
            let daystext = item?.avgActionTime === 1 || item?.avgResolutionTime === 1 ? 'day' : 'days'
            let avgActionsTime = item?.avgActionTime ? item?.avgActionTime + daystext : '0'+daystext;
            let avgResolutionTime = item?.avgResolutionTime ? item?.avgResolutionTime + daystext : '0'+daystext;
            return [item?.alertName,item?.useCase, `${item?.percentageOfPendingEstimatedValue}%`, getFormattedPriceValue(item?.newEstimatedValue, currency), 
            getFormattedPriceValue(item?.actionedEstimatedValue, currency),avgActionsTime, getFormattedPriceValue(item?.UnResolvedEstimatedValue, currency),
            getFormattedPriceValue(item?.resolvedEstimatedValue, currency), avgResolutionTime, getFormattedPriceValue(item?.salesImnpactValue, currency),
            `${item?.salesImnpactPercent}%`,`${item?.effectivenessVsTriggered}%`,`${item?.effectivenessVsTriggeredPOP}%`,`${item?.effectivenessVsTriggeredYOY}%`,
            `${item?.effectivenessVsActioned}%`,`${item?.effectivenessVsActionedPOP}%`,`${item?.effectivenessVsActionedYOY}%`
          ]})
        }
        if(type === 'User') {
          headers = ['User Name', 'Days of usage', 'Usage Cur','Usage PoP','Usage YoY', 'Average Actions Taken Day Cur', 'Average Actions Taken PoP','Average Actions Taken YoY',
          'Actions Taken Estimated Value','Avg Action Time','Unresolved Actions Estimated Value', 'Resolved Estimated Value','Avg Resol. time','Sales Impact Value','Sales Impact PoP',
          'Effectiveness vs.Triggered','Effectiveness Triggered PoP','Effectiveness Triggered YoY','Effectiveness vs.Actioned','Effectiveness Actioned PoP','Effectiveness Actioned YoY']
          
          bodyData = userSummary.responseData.map(item => {
            let daystext = item?.avgActionTime === 1 || item?.avgResolutionTime === 1 ? 'day' : 'days'
            let avgActionsTime = item?.avgActionTime ? item?.avgActionTime + daystext : '0'+daystext;
            let avgActionTakenperDayCur = item?.avgActionTakenperDayCur ? item?.avgActionTakenperDayCur : '0';
            let avgResolutionTime = item?.avgResolutionTime ? item?.avgResolutionTime + daystext : '0'+daystext;
          return [item?.userName, item?.daysOfUsage, `${item?.dailyUsageCurrPercent}%`, `${item?.dailyUsagePOPPercent}%`,`${item?.dailyUsageYOYPercent}%`,avgActionTakenperDayCur, 
          `${item?.avgActionTakenperDayPOP}%`,`${item?.avgActionTakenperDayYOY}%`,getFormattedPriceValue(item?.actionedEstimatedValue, currency), avgActionsTime, getFormattedPriceValue(item?.unResolvedEstimatedValue, currency),
           getFormattedPriceValue(item?.resolvedEstimatedValue, currency),avgResolutionTime,getFormattedPriceValue(item?.salesImpact, currency),`${item?.salesImpactPercent}%`,
          `${item?.effectiveNessVSTriggered}%`,`${item?.effectiveNessVSTriggeredPOP}%`,`${item?.effectiveNessVSTriggeredYOY}%`,`${item?.effectiveNessVSActioned}%`,`${item?.effectiveNessVSActionedPOP}%`,`${item?.effectiveNessVSActionedYOY}%`
        ]})
          
        }  
        
      const sheetData = this.getSheetData(bodyData, headers);
      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
      let fileName = type === "Alert" ? "Alert_Summary.xlsx" : "User_Summary.xlsx"
      saveAs(dataBlob, fileName);  
      }
  };

  getSheetData(data, header) {
    let fields = Object.keys(data[0]);
    let sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  downloadFile = () => {
    let {
      ageofActiveRecommendationsByStrategy,
      allStrategyDetails,
    } = this.props.adoptionValues;
    let combinedObj = {
      singlevals: this.getAdoptionChartData(),
      filters: this.generateFiltersObject(),
      allStrategies: allStrategyDetails,
    };
    let bufferValue = new Buffer(this.state.userInfo.email);
    let base64data = bufferValue.toString("base64");
    this.props.exportAdoptionData({exportData: combinedObj,currentUser: this.state.userInfo.email}, base64data)   
  };
  renderAdoptionReportingFilters = () => {
    if (this.props.displayStrategyFilter) {
      return (
        <div className="d-flex flex-row-reverse bd-highlight">
          <RecommendationFilter
            selectedStrategies={this.state.selectedStrategies}
            handleStrategyClick={this.handleStrategyClickInFilter}
            isAdoption={this.props.isAdoption}
            getSelectedAlerts={this.getSelectedAlerts}
          />
        </div>
      );
    } else
      return (
        <div style={{ marginBottom: 30, marginTop: 30 }}>
          <div class="d-flex flex-row bd-highlight ">
            <div
              className="p-2"
              style={{
                fontWeight: "bold",
                fontSize: 22,
              }}
            >
              {this.props.title}
            </div>            
            <UserCaseFilter />
            <div className="p-1">
              <AlertImpactFilter selectedUsers={this.state.selectedUsers} /> 
            </div>
            <div className="p-1">
              <UserNameFilter
                selectedUsers={this.state.selectedUsers}
              />
            </div>
            <div className="p-1  bd-highlight">
              <CustomDateFilter />
            </div>
            <div class="p-1 ">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic"  style={{background: "rgb(221 217 233)",border:"none",fontWeight: "bold",color: "#0f059e"}}>
                Export <DropDownIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.downloadSummary('Alert')}>By Alert</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.downloadSummary('User')}>By User</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      );
  };

  renderActionView = () => {
    if (
      this.state.selectedStrategies &&
      this.state.selectedStrategies.length > 1
    )
      return null;
    else
      return (
        <div
          style={{
            width: "100%",

            marginLeft: "10px",
            marginRight: "15px",
          }}
        >
          <div
            style={{
              height: "30px",
              width: "89px",
              backgroundColor: "#F5F5F5",
              float: "right",
              paddingTop: "0px",
              marginRight: 41,
            }}
          ></div>
          <input
            placeholder="Summary of action taken..."
            style={{
              backgroundColor: "#F5F5F5",
              height: "126px",
              width: "100%",
              borderRadius: "8px",
              borderTopRightRadius: "0px",
              border: "none",
              outline: "none",
            }}
            value={this.state.actioncomments}
            onChange={(e) => {
              this.setState({ actioncomments: e.target.value });
            }}
          ></input>
          <div className="d-flex">
            <Form.Check
              type="checkbox"
              style={{ marginTop: "10px" }}
              checked={this.state.actionCheckBoxClicked}
              onChange={(e) => {
                this.setState({ actionCheckBoxClicked: e.target.checked });
              }}
              label={"The action taken is not as per the recommendation"}
            />
            <button
              className={`btn ml-auto ${
                this.state.actioncomments === "" && "btn-none"
              }`}
              style={{
                background: "#0F059E",
                fontWeight: "bold",
                color: "white",
                marginLeft: 5,
              }}
              onClick={this.handleActionSaveButtonClicked}
            >
              {`Move to ${
                this.props.newRecommendationStrategySelection.selection ===
                "active"
                  ? "Actioned"
                  : "Active"
              }`}
            </button>
          </div>
        </div>
      );
  };
  handleExpandTableButtonClick = () => {
    let statusOfButton = this.state.expandButtonClicked;
    let text = "expand";
    if (!statusOfButton) text = "compress";
    this.setState(
      { expandButtonClicked: !statusOfButton, fullScreenIcon: text },
      () => {
        this.props.storeExpandButtonClickedInfo(!statusOfButton);
      }
    );
  };

  renderExpandButton = () => {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip id="tooltip-disabled">
            {this.props.isExpandTableButtonClicked
              ? "Exit fullscreen"
              : "Enter fullscreen"}
          </Tooltip>
        }
        popperConfig={{
          modifiers: {
            preventOverflow: {
              enabled: false,
            },
          },
        }}
        delay={{ show: 250, hide: 400 }}
        placement={this.props.isExpandTableButtonClicked ? "bottom" : "top"}
      >
        <button
          className="btn "
          style={{
            background: "#F5F5F5",
            fontWeight: "bold",
            color: "#0f059e",
            marginLeft: 5,
          }}
          onClick={(e) => {
            this.handleExpandTableButtonClick();
          }}
        >
          <i className={`fas fa-${this.state.fullScreenIcon}-alt`}></i>
        </button>
      </OverlayTrigger>
    );
  };
// hard code for snooze
  getActionText = ()=>{
    return  this.props.newRecommendationStrategySelection && this.props.newRecommendationStrategySelection.selection == "actioned" ?  this.props.isSnoozeSubProject ? "Move To Active" : "Action" : "Action"
     
  }
  renderActionButton = () => {
    return _isEqual(this.props.newRecommendationSelectedRows, {}) && _isEqual(this.props.newSelectAllProductList, []) ? (
      <OverlayTrigger
        overlay={
          <Tooltip id="tooltip-disabled">
            {this.props.selectedStrategies.length <= 0
              ? "Select an alert to take action"
              : "Select a product to take Action"}
          </Tooltip>
        }
        popperConfig={{
          modifiers: {
            preventOverflow: {
              enabled: false,
            },
          },
        }}
        delay={{ show: 250, hide: 400 }}
        placement={this.props.isExpandTableButtonClicked ? "bottom" : "top"}
      >
        <span className="d-inline-block">
          <button className="btn ml-2 btn-none"               
         >
         {this.getActionText()}  {!this.props.isSnoozeSubProject && <DropDownIcon />}
          </button>
        </span>
      </OverlayTrigger>
    ) : (
      <button
        className={`btn ${
          this.state.actionButtonClicked && !this.props.isSnoozeSubProject
            ? "action-button-clicked"
            : "action-button"
        }`}
        onClick={(e) => {
          this.handleActionButtonClick();
          this.props.isSnoozeSubProject && this.props.newRecommendationStrategySelection.selection === "actioned" && this.handleActionSaveButtonClicked()
        }}
      >
     {this.getActionText()}
        {!this.props.isSnoozeSubProject ? this.state.actionButtonClicked ? <CloseIcon /> : <DropDownIcon /> : null }
      </button>
    );
  };
  searchAlertInfoToolTip = () => {
    
  }
  handleNewRecommendationSearch = (e) => {    
   if (e.keyCode == 13) {
      this.props.storeNewRecomSearchText(e.target.value);
    } 
  };
  handleNewRecommendationSearchClick = (e) => { 
    this.setState({ newRecomSearchText: e.target.value });
    if(this.state.newRecomSearchText !== undefined) {
      this.props.storeNewRecomSearchText(this.state.newRecomSearchText);
    }
  };
  handleNewRecomenSearchTextChange = (e) => {
    this.setState({ newRecomSearchText: e.target.value });
    if(e.keyCode == 13) {
      this.props.storeNewRecomSearchText(this.state.newRecomSearchText);
    }
  };

  renderFocusAlertInfoToolTip = () => {
    let alertsRow = this.props.newRecommendationStrategySelection[
      "activeRowDetails"
    ];

    return (
      <Tooltip id="comments-tooltip">
        <div style={{ textAlign: "left" }}>
          {alertsRow.INFO ? (
            <div>
              <i class="fas fa-info-circle" style={{ paddingLeft: "5px" }}></i>{" "}
              {alertsRow.INFO}
            </div>
          ) : null}
          {alertsRow.PRIMARY_ACTION ? (
            <div>
              <span style={{ fontWeight: "bold" }}>Primary Action :</span>{" "}
              {alertsRow.PRIMARY_ACTION}
            </div>
          ) : null}
          {alertsRow.SECONDARY_ACTION ? (
            <div>
              <span style={{ fontWeight: "bold" }}>Secondary Action : </span>
              {alertsRow.SECONDARY_ACTION}
            </div>
          ) : null}
        </div>
      </Tooltip>
    );
  };
  renderActiveAlertsText = () => {
    if (     
      this.props.newRecommendationStrategySelection && this.props.newRecommendationStrategySelection.strategyName &&
      this.props.newRecommendationStrategySelection["activeRowDetails"]
    )
      return (
        <span>
          {" "}
          <span
            style={{
              fontWeight: "bolder",
              marginLeft: "30px",
              color: "#58595B",
            }}
          >
            Focus Alert:{" "}
          </span>
          <span className="clickable-span" style={{ paddingRight: "5px" }}>
            {this.props.newRecommendationStrategySelection.strategyName === 'Competitor OOS' ? 'Competition Out Of Stock' : this.props.newRecommendationStrategySelection.strategyName}
          </span>
          valued at
          <span style={{ fontWeight: "bolder", color: "#58595B" }}>
            {" "}
            {this.props.newRecommendationStrategySelection["estimatedValue"] === "N/A" || this.props.newRecommendationStrategySelection["estimatedValue"] === 0 ? "N/A" : generateFormattedEstimateValue(
              this.props.newRecommendationStrategySelection["estimatedValue"] 
            )}
          </span>
          <OverlayTrigger
            placement="auto"
            delay={{ show: 250, hide: 400 }}
            overlay={this.renderFocusAlertInfoToolTip()}
          >
            <i
              class="fas fa-info-circle"
              style={{
                paddingLeft: "5px",
                color: "#58595B",
                cursor: "pointer",
              }}
            ></i>
          </OverlayTrigger>
        </span>
      );
  };
  render() {
    if (this.props.isAdoption) {
      return this.renderAdoptionReportingFilters();
    } else    
      return (
        <div>
          <ToastContainer />

          <div className="row plr-0 mx-0 align-items-center">
            <div className="row align-items-center w-100 ml-1 mr-2 filter-mt">
              {this.props.isNewRecommendationPage ? null : (
                <div className="col-md-6 col-sm-12 p-0 flex-align-ends">
                  <div className="d-flex flex-column">
                    <div>{this.props.subtitle}</div>
                    <div className="header">{this.props.title}</div>
                  </div>
                </div>
              )}
              {/* {this.props.isNewRecommendationPage &&
                ( this.props.newRecommendationStrategySelection &&
                this.props.newRecommendationStrategySelection.selection ? ( */}
                 {( this.props.isNewRecommendationPage  ? ( 
                  <span
                    style={{
                      fontWeight: 400,
                      marginLeft: 8,
                    }}
                  >
                    Showing{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.currentCount}
                    </span>{" "}
                    of{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.totalCount}
                    </span>{" "}
                    {this.props.selectedStrategies?.length && this.props.selectedStrategies[0] === 'Share of Search' ? 'Keywords' : 'SKUs'}
                  </span>
                ) : (                  
                  null
                ))}
                {this.props.isNewRecommendationPage ? (
                <>
                  <input
                    type="text"
                    class="mt-1 search-input"
                    id="input-{ label }"
                    placeholder="Search for product"
                    value={this.state.newRecomSearchText}
                    onChange={this.handleNewRecomenSearchTextChange}
                    onKeyDown={this.handleNewRecommendationSearch}                    
                  />
                  <i class="fa fa-search" onClick={this.handleNewRecommendationSearchClick} style={{position:"relative",right:"34px",top:"2px",cursor:"pointer",color:"rgb(88, 89, 91)"}}></i>
                   {this.renderActiveAlertsText()}
                  </>
                ) : null}
             

              <div className="ml-auto ">
                <div
                  className={`${
                    this.props.isNewRecommendationPage
                      ? "new-recommendation"
                      : "filterRow filterrow-mt"
                  } flex `}
                >
                  <div className="d-flex">
                    {this.props.displayDateFilter ? (
                      <DateFilter
                        lastRefreshedDate={this.props.lastRefreshedDate}
                        ytdStartDate={this.props.ytdStartDate}
                        handleApplyDateFilter={this.handleApplyDateFilter}
                        selectedDateRange={this.props.selectedDateRange}
                        compareToOption={this.props.compareToOption}
                        defaultDateRange={this.props.defaultDateRange}
                        transMaxDate={this.props.transMaxDate}
                        newDateDefault={this.props.newDateDefault}
                      />
                    ) : null}
                    {this.props.displayUnsubscribeButton ? (
                      <div>
                        <button
                          className="btn btn-cta subscription-filter"
                          onClick={(e) => {
                            this.props.handleUnsubscribeStrategy(
                              this.props.strategyIndex
                            );
                          }}
                        >
                          Unsubscribe <CloseIcon />
                        </button>
                      </div>
                    ) : null}
                    {this.props.isNewRecommendationPage && <><RankTypeFilter /> <AlertDateFilter /></> }
                    {this.props.displayStrategyFilter ? (
                      <RecommendationFilter
                        selectedStrategies={this.state.selectedStrategies}
                        handleStrategyClick={this.handleStrategyClickInFilter}
                        isNewRecommendationPage={
                          this.props.isNewRecommendationPage
                        }
                        getSelectedAlerts={this.getSelectedAlerts}
                      />
                    ) : null}
                    {this.props.displaySpecificdate ? <InsightsDateFilter /> : null }
                    {this.props.isNewRecommendationPage ? null : (
                      <Dropdown
                        onToggle={this.onToggle}
                        show={this.state.displayFilterDropdown}
                        id={`${
                          this.props.hideFilteredRow
                            ? "new-recommendation-global-filter"
                            : "globalFilter"
                        }`}
                      >
                        <Dropdown.Toggle
                          variant="light"
                          id="dropdown-basic"
                          className={` ${
                            this.props.isNewRecommendationPage
                              ? "recommendation-global-filter"
                              : this.props.displayUnsubscribeButton
                              ? "subscription-filter"
                              : ""
                          } ${
                            this.props.hideFilteredRow
                              ? "hide-filter-row"
                              : "btn-cta"
                          }`}
                        >
                          <FilterIcon />
                          <span>
                            Add Filter <DropDownIcon />
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {this.renderGlobalFilters()}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}

                    {
                    this.props.isNewRecommendationPage ? (
                      <>
                      {this.props.isSnoozeSubProject ? (<div class="dropdown">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdownMenuLink" style={{
                          background: "#0F059E",
                          fontWeight: "bold",
                          color: "white",
                          marginLeft: 5,
                        }}  disabled={this.props.selectedStrategies &&
                          this.props.selectedStrategies.length === 0 }>
                        Export <DropDownIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={this.downloadRecommendationCSVFile}>By Status</Dropdown.Item>
                          <Dropdown.Item onClick={this.downloadAlertLogHistoryCSVFile}>By History</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                </div>): <button
                          className="btn "
                          style={{
                            background: "#0F059E",
                            fontWeight: "bold",
                            color: "white",
                            marginLeft: 5,
                          }}
                          onClick={this.downloadRecommendationCSVFile}
                        >
                          Export
                        </button>}
                        
                        {this.renderActionButton()}
                        {this.renderExpandButton()}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {this.state.actionButtonClicked &&
            this.props.selectedStrategies &&
            this.props.selectedStrategies.length > 0 &&
            this.props.isNewRecommendationPage &&
            this.props.newRecommendationStrategySelection.selection 
              && !this.props.isSnoozeSubProject ? this.renderActionView()
              : null}

            {!this.props.hideFilteredRow
              ? this.renderSelectedFilterRow()
              : null}
          </div>
        </div>
      );
  }
}
function mapStateToProps(state) {
  let savedFilters = _get(state, "globalReducer.savedFilters.responseData", []);
  let { project, subproject } = _get(state, "globalReducer", "");

  let globalFilter = _get(state, "globalReducer.globalFilter.data", []);
  let saveFilters = _get(
    state,
    "recommendationsReducer.savedFilters.responseData",
    []
  );
  let selectedGlobalFilters = _get(
    state,
    "globalReducer.selectedGlobalFilters",
    {}
  );
  let preselectedGlobalFilters = _get(
    state,
    "globalReducer.preselectedGlobalFilters",
    {}
  );
  let noDepValueFilters = _get(
    state,
    "globalReducer.noDepValueFilters",
    []
  );
  let selectedGlobalFilterValues = _get(
    state,
    "globalReducer.selectedGlobalFilterValues",
    {}
  );
  let {saveFilterClicked, isSnoozeSubProject, selectedRetailerCurrency, defaultAlertFilterCondition} = _get(state, "globalReducer", false);
  
  let selectedStrategies = _get(
    state,
    "strategyReducer.selectedStrategies",
    []
  );
  
  let actionedAndActiveData = _get(
    state,
    "recommendationsReducer.actionedAndActiveData"
  );

  let newRecommendationStrategySelection = _get(
    state,
    "strategyReducer.recommendationSelectedStrategy"
  );
  let newRecommendationSelectedRows = _get(
    state,
    "newRecommendationTableData.selectedRows"
  );
  let newSelectAllProductList = _get(state, "newRecommendationTableData.selectAllProductList")
  let {allSkuIds,selectedSkuIds} = _get(state, "newRecommendationTableData")
  let {selectedStrategyDateRange, strategyCustomDates, selectedFilterCondition, rankTypefilter} = _get(state, "strategyReducer")
  let {
    curentIndex,
    sortDirecetion,
    sortedColumn,
    isExpandTableButtonClicked,
    currentCount,
    totalCount
  } = _get(state, "newRecommendationTableData");
  let newRecoSearchText = _get(
    state,
    "newRecommendationTableData.newRecoSearchText"
  );
  let adoptionValues = _get(state, "adoptionReducer");

  return {
    savedFilters: savedFilters,
    saveFiltersResponse: saveFilters,
    globalFilter,
    newRecommendationSelectedRows,
    newSelectAllProductList,
    newRecommendationStrategySelection,
    adoptionValues,
    selectedStrategies,
    selectedGlobalFilters,
    preselectedGlobalFilters,
    noDepValueFilters,
    selectedGlobalFilterValues,
    saveFilterClicked,
    newRecoSearchText,
    isSnoozeSubProject,
    selectedRetailerCurrency,
    project,
    subproject,
    curentIndex,
    sortDirecetion,
    sortedColumn,
    isExpandTableButtonClicked,
    totalCount,
    currentCount,
    selectedSkuIds,
    allSkuIds,
    actionedAndActiveData,
    selectedStrategyDateRange,
    strategyCustomDates,
    defaultAlertFilterCondition,
    selectedFilterCondition,
    rankTypefilter
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveFiltersAPI: saveFilters,
      deleteFilterAPI: deleteFilter,
      getSavedFiltersAPI: getSavedFilters,
      getLastDataRefreshDateAPI: getLastDataRefreshDate,
      getGlobalFilterAPI: getGlobalFilter,
      storeRecommendationSelectedStrategies,
      storeGlobalFilterSelections,
      storeGlobalFilterValues,
      storeSaveFilterClicked,
      storeExpandButtonClickedInfo,
      storeNewRecomSearchText,
      exportAdoptionData,
      saveDateRangeFilterClicked: saveDateRangeFilter,
      recommendationTableContent,
      strategySelectedDateRange,
      strategyFilterAndOrCondition
    },
    
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOktaAuth(Filters));
